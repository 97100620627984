// node modules
import React from 'react'
import { graphql, PageProps } from 'gatsby'
import styled from 'styled-components'

// layout
import Layout from 'components/layout/layout'
import Seo from 'components/layout/seo'

// hooks
import usePopup from 'hooks/usePopup'

// shared
import Container from 'components/shared/container'
import { Heading } from 'components/shared/typography'

// styles
import media from 'styles/media'

const Wrapper = styled.div`
  padding: 185px 0 80px 0;

  ${media.lg.max} {
    padding: 140px 0 60px 0;
  }
`

const ContentWrapper = styled.div``

const PolitykaPrywatnosci: React.FC<
  PageProps<Queries.PolitykaPrywatnosciPageQuery>
> = ({ data }) => {
  const PAGE = data.wpPage?.politykaPrywatnosci
  const PAGE_SEO = data.wpPage?.seo!

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  const POPUP = {
    image: {
      src: PAGE?.popupImg?.localFile?.childImageSharp?.gatsbyImageData!,
      alt: PAGE?.popupImg?.altText ?? '',
    },
    delay: PAGE?.popupDelay,
    link: PAGE?.popupLink ?? null,
    visibleOnMobile: PAGE?.popupVisibleOnMobile ?? false,
  }

  usePopup(POPUP)

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Wrapper>
        <Container slim>
          <Heading
            dangerouslySetInnerHTML={{ __html: 'Polityka prywatności' }}
            transform="uppercase"
            size={36}
          />
          <ContentWrapper
            dangerouslySetInnerHTML={{
              __html: PAGE!.privacyPolicy!,
            }}
          ></ContentWrapper>
        </Container>
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query PolitykaPrywatnosciPage {
    wpPage(slug: { regex: "/polityka-prywatnosci/" }) {
      politykaPrywatnosci {
        privacyPolicy
        popupImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        popupDelay
        popupLink
        popupVisibleOnMobile
      }
      seo {
        ...WpSEO
      }
    }
  }
`

export default PolitykaPrywatnosci
